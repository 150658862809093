import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import SquareFootIcon from '@mui/icons-material/SquareFoot'
import CssBaseline from '@mui/material/CssBaseline'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

function Copyright() {
  return (
    <Typography variant='body2' color='text.secondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='https://www.lelimit.no'>
        lelimit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  )

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position='relative'>
        <Toolbar>
          <SquareFootIcon sx={{ mr: 2 }} />
          <Typography variant='h6' color='inherit' noWrap>
            LELIMIT AS
          </Typography>
        </Toolbar>
      </AppBar>
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth='sm'>
            <Typography
              component='h1'
              variant='h2'
              align='center'
              color='text.primary'
              gutterBottom
            >
              There's no limit
            </Typography>
            <Typography variant='h5' align='center' color='text.secondary' paragraph>
            A company based in Norway, a solution provider of consultancy services for business challenges and of full stack solutions for web and mobile apps which are available on Google Play for Android and App Store for iOS.
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction='row'
              spacing={2}
              justifyContent='center'
            >
              <Button variant='contained' href={`mailto:info@lelimit.no`}>Contact</Button>
            </Stack>
          </Container>
        </Box>
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth='sm'>
            <Typography
              component='h1'
              variant='h2'
              align='center'
              color='text.primary'
              gutterBottom
            >
              Solutions
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction='row'
              spacing={2}
              justifyContent='center'
            >
              <img src={require('./boligassistent.png')} alt='www.boligassistent.no' height='300' width='300' />
            </Stack>
            <Typography variant='h4' color='text.secondary' align='center'>              
              <Link color='inherit' href='https://www.boligassistent.no'>
                Boligassistent
              </Link>              
            </Typography>
            <Typography variant='body2' align='center'>
                Your personal assistant for managing tenancies with absolute control
            </Typography>
          </Container>
        </Box>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component='footer'>
        <Typography variant='h6' align='center' gutterBottom>
          LELIMIT AS
        </Typography>
        <Typography
          variant='subtitle1'
          align='center'
          color='text.secondary'
          component='p'
        >
          915 212 107
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}